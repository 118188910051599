body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: -moz-linear-gradient(top, rgba(177,177,155,0.3) 0%, rgba(177,177,155,0.3) 50%, rgba(177,177,155,1) 50%, rgba(177,177,155,1) 100%);
  background: -webkit-linear-gradient(top, rgba(177,177,155,0.3) 0%,rgba(177,177,155,0.3) 50%,rgba(177,177,155,1) 50%,rgba(177,177,155,1) 100%);
  background: linear-gradient(to bottom, rgba(177,177,155,0.3) 0%,rgba(177,177,155,0.3) 50%,rgba(177,177,155,1) 50%,rgba(177,177,155,1) 100%);
  
  

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
