.App {
  width: 100vw;
  height: 100vh;
  background-image: url(./img/HomeLogo.png);
  background-position: center center;
  background-size: 30%;
  background-repeat: no-repeat;
  text-align: center;
}

button{
  width: 200px;
  height: 50px;
  border-radius: 25px;
  filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.3));
  position:absolute;
  left: 43%;
  bottom: 20%;
  background-image: url(./img/icoHelp.svg);
  background-position: left ;
  background-size: 25%;
  background-repeat: no-repeat;
  padding: 0px 0px 0px 50px;
}

@media screen and (max-width: 992px) {
  .App {
    width: 100vw;
    height: 100vh;
    background-image: url(./img/HomeLogo.png);
    background-position: center center;
    background-size: 90%;
    background-repeat: no-repeat;
    text-align: center;
  }
  
  button{
    opacity: 0;
  }
}

@media screen and (max-width: 600px) {
  .App {
    width: 100vw;
    height: 100vh;
    background-image: url(./img/HomeLogo.png);
    background-position: center center;
    background-size: 90%;
    background-repeat: no-repeat;
    text-align: center;
  }
  
  button{
    opacity: 0;
  }
}
